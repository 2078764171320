import React, { Fragment, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image/withIEPolyfill';
import SwiperSlider from '../slider/swiper';
import { singleImageContent } from '../../helpers/swiper-images';
import './news.scss';

function DisplayNews() {
  const [selectedImage, selectImage] = useState(null);
  const { allWordpressPost } = useStaticQuery(
    graphql`
      query WPNews {
        allWordpressPost(
          filter: { categories: { elemMatch: { name: { eq: "News" } } } }
          sort: { fields: acf___data_wydarzenia, order: DESC }
        ) {
          edges {
            node {
              id
              title
              slug
              content
              acf {
                data_wydarzenia
                content_image {
                  localFile {
                    name
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  return (
    <Fragment>
      <div className={'news'}>
        {allWordpressPost.edges.map((item) => (
          <div className={'news__item'} key={item.node.id}>
            <p className={'news__item--date'}>
              {dataParser(item.node.acf.data_wydarzenia)}{' '}
            </p>
            <div
              className={'news__item--content'}
              dangerouslySetInnerHTML={{
                __html: item.node.content,
              }}
            />
            {item.node.acf.content_image && (
              <button
                className={'button button__gallery'}
                onClick={() => selectImage(item.node.acf.content_image)}
              >
                <Image
                  fluid={
                    item.node.acf.content_image.localFile.childImageSharp.fluid
                  }
                  className={'news__item--image'}
                  objectFit='contain'
                  objectPosition='50% 50%'
                  alt={`news ${item.node.title}`}
                />
              </button>
            )}
          </div>
        ))}
      </div>
      {selectedImage && (
        <SwiperSlider
          images={singleImageContent(selectedImage)}
          index={0}
          onClose={() => selectImage(null)}
        />
      )}
    </Fragment>
  );
}

function dataParser(data) {
  return new Date(data).toLocaleDateString('en-GB');
}

export default DisplayNews;
