import React from 'react';
import Layout from '../../components/layouts/layout';
import DisplayNews from '../../components/news/news-posts';
import './posts.scss';

export default ({ pageContext }) => (
  <Layout header={pageContext.name}>
    {pageContext.name === 'News' && <DisplayNews />}
  </Layout>
);
